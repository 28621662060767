<template>
  <div class="donateInfo">
    
      <!-- :title="['捐款信息','捐物信息','资金使用信息']" -->
    <TitleMore
      :isMore="false" 
      :title="['捐款信息','捐物信息','款项拨付']"
      @handleTab="handleTab"
    ></TitleMore>
    <div class="baseCard donateTop">
      <el-form
        v-show="activeIndex!=1"
        label-width="100px"
        label-position="right"
        class="donateForm"
      >
        <el-form-item
          :label="activeIndex==0?'捐赠人名称':'姓名'"
          style="width:100%"
        >
          <el-input
            style="width:100%"
            v-model="donateName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item :label="activeIndex==0?'捐赠金额':'金额'">
          <el-input
            style="width:46%"
            v-model="minAmount"
            placeholder="请输入"
          ></el-input>
          <p style="text-align:center;width:10%">至</p>
          <el-input
            style="width:46%"
            v-model="maxAmount"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item :label="activeIndex==0?'捐赠日期':'日期'">
          <el-date-picker
            style="width:46%"
            v-model="startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
          <p style="text-align:center;width:10%">至</p>
          <el-date-picker
            style="width:46%"
            v-model="endDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="getdonateMsg"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <el-form
        v-show="activeIndex==1"
        label-width="100px"
        label-position="right"
        class="donateForm"
      >
        <el-form-item
          label="搜索关键字"
          style="width:100%"
        >
          <el-input
            style="width:100%"
            v-model="title"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submitBtn"
            @click="onOtherSearch"
          >立即查询</el-button>
        </el-form-item>
      </el-form>
      <div class="donateIntro">
        <p style="
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin:10px 0px">查询说明</p>
        <p style="
          font-size: 16px;
          color: #666666;
          line-height: 28px;">
          <template v-if="activeIndex==0">
            如果您在本页面未查询到自己的捐赠信息，可能存在以下情况： 1、输入的查询条件是否准确； 2、收款单位是否为四川省红十字会。如果收款单位为四川省红十字基金会、 地方红十字会，请您联系相应的收款单位查询； 3、如果仍未查到捐款信息，您可通过电话028-84350282（工作日）进行查询； 4、本平台可查询2010年3月26日以来向四川省红十字会捐赠信息。 感谢您的爱心捐赠！
          </template>
          <template v-if="activeIndex==1">
            如果您未在本页面查询到自己的捐赠信息，可能存在以下情况： 1、输入的查询条件是否准确； 2、捐赠接收单位是否为四川省红十字会。如果捐赠接收单位为四川省红十字基金会、 地方红十字会，请您联系相应单位查询； 3、如果仍未查到捐赠信息，您可通过电话028-84350282（工作日）进行查询； 4、本平台可查询2010年3月26日以来向四川省红十字会捐赠信息。 感谢您的爱心捐赠！
          </template>
          <template v-if="activeIndex==2">
            如果您未在本页面查询到自己的捐赠信息，可能存在以下情况： 1、输入的查询条件是否准确； 2、收款单位是否为四川省红十字会。如果收款单位为四川省红十字基金会、 地方红十字会，请您联系相应的收款单位查询； 3、如果仍未查到捐赠信息，您可通过电话028-84350282（工作日）进行查询； 4、本表仅可查询2024年1月1日后四川省红十字会拨付的定向捐赠资金，既往情况请前往本网站【信息公开】栏目查询。 感谢您的爱心捐赠！
          </template>
        </p>
      </div>
    </div>
    <div class="baseCard donateBottom">
      <!-- <p style="
        font-size: 24px;
        font-weight: bold;
        color: #555454;
        line-height: 48px;
        text-align:center">四川省红十字会{{`${activeIndex==0?'资金接受':activeIndex==1?'捐物':'捐赠资金使用'}`}}公示表</p>
      <TitleLine width="350px"></TitleLine> -->
      <template v-if="activeIndex==0">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          key="tab01" 
        >
          <el-table-column
            style="background:red"
            prop="donator_name"
            label="姓名"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="amount"
            label="金额(元)"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="create_date"
            label="时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="donation_message"
            label="备注"
            width="180"
            align="center"
          >
          </el-table-column>
        </el-table>
      </template>
      <div
        class="donateInfo"
        v-if="activeIndex==1"
      >
        <div
          class="itemText"
          v-for="(item,index) in listData"
          :key="index"
        >
          <p @click="$goto('/detailShow',{id:item.id})">
            <img
              src="../../assets/img/list-little.png"
              alt=""
            >
            <span class="itemTextContent">{{item.title}}</span>
          </p>
          <p class="itemTextTime">{{item.publishDate}}</p>
        </div>
      </div>
      <template v-if="activeIndex==2">
        <el-table
          :data="moneyData"
          stripe
          style="width: 100%"
          key="tab02" 
          >
          <el-table-column
            property="index"
            label="序号"
            width="80">
          </el-table-column>
          <el-table-column
            property="title"
            label="捐赠单位">
          </el-table-column>
          <el-table-column
            property="donation"
            label="捐赠意愿">
          </el-table-column>
          <el-table-column
            property="situation"
            label="使用情况">
          </el-table-column>
          <el-table-column
            property="author"
            label="使用金额（元）">
          </el-table-column>
          <el-table-column
            property="subtitle"
            label="拨付时间">
          </el-table-column>
          <el-table-column
            property="remark"
            label="备注">
          </el-table-column>
        </el-table>
      </template>
      <el-pagination
       v-if="activeIndex!=2"
        style="margin-top:30px"
        :current-page="page"
        :page-size="size"
        :total="total"
        @current-change="handleCurrentChange"
      ></el-pagination>
      <el-pagination
        v-if="activeIndex==2"
        style="margin-top:30px"
        :current-page="page"
        :page-size="size"
        :page-sizes="[10, 20, 50, 100] "
        :total="total"
        layout="total, prev, pager, next, sizes, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore.vue";
import TitleLine from "@components/TitleLine.vue";
import apiUrls from "@api";
export default {
  data() {
    return {
      activeIndex: 0,
      donateName: "",
      minAmount: "",
      maxAmount: "",
      startDate: "",
      endDate: "",
      dateValue: "",
      tableData: [],
      moneyData: [],
      searchParams: {},//款项拨付搜索条件
      page: 1,
      size: 15,
      total: 0,
      title: "",
      listData: [],
      type: "",
      node:''
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    const type = this.$route.query.type;
    if (type == 1) {
      this.activeIndex = 0;
      this.donateName = this.$route.query.donateName;
      this.minAmount = this.$route.query.minAmount;
      this.maxAmount = this.$route.query.maxAmount;
      this.startDate = this.$route.query.startDate;
      this.endDate = this.$route.query.endDate;
      this.getdonateMsg();
    } else if (type == 2) {
      this.activeIndex = 1;
      this.title=this.$route.query.donateName
      this.getDonateList()
    } else if (type == 3) {
      this.activeIndex = 2;
      this.title=this.$route.query.donateName
      this.getDonateList()
    } else {
      this.getdonateMsg();
    }
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.activeIndex == 0
      ? this.getdonateMsg()
      : this.activeIndex == 1
      ? this.getDonateList('xxgk_jwgs')
      : this.activeIndex == 2
      ? this.getDisbursement()
      : this.getDonateList('zjsygs');
    },
    handleSizeChange(val){
      this.page = 1;
      this.size = val
      this.getDisbursement()
    },
    onOtherSearch() {
      this.type == 341 ? this.getDonateList() : this.getDonateList();
    },
    handleTab(index) {
      this.title = "";
      this.activeIndex = index;
      this.page = 1;
      if(index==2){
        this.size= 10
      }else{
        this.size=15
      }
      this.donateName=''
      this.minAmount=''
      this.startDate=''
      this.maxAmount=''
      this.endDate=''
      index == 0
        ? this.getdonateMsg()
        : index == 1
        ? this.getDonateList('xxgk_jwgs')
        : index == 2
        ? this.getDisbursement()
        : this.getDonateList('zjsygs');
    },
    // 款项拨付列表
    getDisbursement() {
      apiUrls.selfDynamicList({node: 'xxgk_kxbf', size:this.size,page:this.page-1,...this.searchParams}).then(res=>{
        const results = res.results;
        this.moneyData = ( results.data || []).map((el,index)=>({ ...el,index: index + 1 }));
        this.total = results.pager.totalElements || 0;
      })
    },
    // 款项拨付搜索
    onSearch(){
      this.page = 1
      const params = {
        "search_CONTAIN_detail.title": this.donateName,
        "search_GTE_detail.author_Integer": this.minAmount,
        "search_GTE_detail.subtitle": this.startDate,
        "search_LTE_detail.author_Integer": this.maxAmount,
        "search_LTE_detail.subtitle": this.endDate,
      };
      this.searchParams = params
      this.getDisbursement()
    },
    getdonateMsg() {
      if(this.activeIndex==2){
        this.onSearch()
        return
      }
      apiUrls
        .donateMsg({
          pageNumber: this.page,
          pageSize: this.size,
          donateName: this.donateName,
          minAmount: this.minAmount,
          maxAmount: this.maxAmount,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then((res) => {
          this.tableData = res.results.list;
          this.total = res.results.pager.totalElements;
          console.log('this.tableData',this.tableData)
        });
    },
    getDonateList(type) {
      if(type){
        this.node = type;
      }
      apiUrls.keyWords({modelId:2,nodeId: 256, page: this.page-1,size:this.size,searchKeyword:this.title}).then(res=>{
        this.total = res.results.pager.totalElements;
        this.listData= res.results.data;
      })
      // apiUrls
      //   .otherDonate({
      //     nodeId: type,
		  // modelId:modle,
      //     pageNumber: this.page,
      //     pageSize: this.size,
      //     searchKeyword: this.title,
      //   })
      //   .then((res) => {
			// console.log(res)
      //     this.listData = res.results.data;
      //     this.total = res.results.pager.totalElements;
      //   });
    },
  },
};
</script>
<style lang="less" scoped>
.donateInfo {
  margin: 20px 0px 40px 0px;
  /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }
  .donateTop {
    display: flex;
    justify-content: space-between;
    .donateForm {
      width: 40%;
      padding: 0px 3%;
    }
    .donateIntro {
      width: 50%;
      background: #ffffff;
      border: 1px solid #ff7b7f;
      border-radius: 8px;
      padding: 10px 20px;
    }
  }
  .donateBottom {
    /deep/.el-table th,
    .el-table tr {
      background-color: #f4e2c8;
      color: #ae6d23;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>